<template>
    <page-header-wrapper>
        <a-card :bordered="false">
            <div class="table-page-search-wrapper">
				<a-form :form="form" :label-col="{ span: 3 }" :wrapper-col="{ span: 11 }" @submit="handleSubmit">
				    <a-form-item label="答题主题" labelAlign="left">
				        <a-input v-decorator="['title', { rules: [{ required: true, message: '请输入答题主题!' }] }]" placeholder="请输入答题主题" />
				    </a-form-item>
					<a-form-item label="封面图" labelAlign="left">
						<a-upload
						    :action="imgUpload"
						    list-type="picture"
							:data="dataObj"
                            :headers="headers"
							:default-file-list="fileList"
						    class="upload-list-inline" @change="handleChange">
						    <a-button v-show="!this.coverUrl"> <a-icon type="upload" /> 点击上传 </a-button>
						</a-upload>
						<div>支持.jpg、.png 格式，建议尺寸750*314</div>
					</a-form-item>
					<a-form-item label="答题规则" labelAlign="left">
						<a-select
						    v-decorator="['type',{ rules: [{ required: true, message: '请选择答题规则!' }] },]"
						    placeholder="请选择答题规则">
						    <a-select-option :value="item.id" v-for="(item,index) in ruleData" :key="index">
						        {{item.label}}
						    </a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="奖励规则" labelAlign="left">
						每道题奖励
						<a-input-number 
							v-decorator="['point',{ rules: [{ required: true, message: '请选择答题规则!' }] },]"
							id="inputNumber" :min="0" :max="100000" />
						积分
					</a-form-item>
					<a-form-item label="答题时间(分钟)" labelAlign="left">
						<a-input-number 
							v-decorator="['time',{ rules: [{ required: true, message: '请输入答题时间!' }] },]"
							id="inputNumber" :min="0" :max="300" style="width:100%" />
					</a-form-item>
					<a-form-item label="抽题数" labelAlign="left"> 
						<a-input-number 
							v-decorator="['number',{ rules: [{ required: true, message: '请输入答题时间!' }] },]"
							id="inputNumber" :min="0" :max="300" style="width:100%" />
					</a-form-item>
					<a-form-item label="活动时间" labelAlign="left">
						<a-range-picker show-time  format="YYYY-MM-DD HH:mm:ss"
							v-decorator="['date', { rules: [{ required: true, message: '请选择活动时间!' }] }]" 
							:placeholder="['活动开始时间', '活动结束时间']"
							style="width:100%">
						</a-range-picker>
					</a-form-item>
				    <a-form-item :wrapper-col="{ span: 24 }" style="text-align: center;">
				        <a-button type="primary" html-type="submit">
				            确定
				        </a-button>
				        <a-button type="default" style="margin-left: 10px;" @click="reset">
				            取消
				        </a-button>
				    </a-form-item>
				</a-form>
            </div>
        </a-card>
    </page-header-wrapper>
</template>

<script>
    import moment from 'moment'
    import { Modal,message } from 'ant-design-vue'
    import VueUeditorWrap from 'vue-ueditor-wrap'
    import { getUrlKey,UEDITOR_HOME_URL,serverUrl,imgUpload,prefix,decrypt } from '@/utils' 
    import {
        getList,
        deliv,
        getWqrInfo,
        editWqrInfo,
        getWqrType
    } from '@/api/article'
	import { getDetail,_edit,_add } from '@/api/answer.js';
    import storage from 'store';
    import { ACCESS_TOKEN } from '@/store/mutation-types';
    var disabled = true
    export default {
        name: '',
        components: {
            VueUeditorWrap
        },
        data() {
            return {
                category:[],    //分类
                imgUpload:imgUpload,  //图片上传
                fileList:[],   //已经上传的文件列表
                prefix:prefix,
				dataObj:{
					is_thumb:1
				},
                headers:{
                    token:storage.get(ACCESS_TOKEN)
                },
				form:this.$form.createForm(this),
				coverUrl:'',   //封面图路径
				rule:'1',
				ruleData:[
					{id:'1',label:'活动期间仅答题1次'},
					{id:'2',label:'活动期间每日答题1次'}
				],
				id:'',
            }
        },
        created(){
            this.id = getUrlKey('id')||'';
            if(this.id){
                this.getDetail();
            }
			
        },
        methods: {
            getCateList(){   //获取分类
                let params = {}
                getWqrType(params).then(res =>{
                    if(res.code==200){
                        this.category = res.msg
                    }
                })
            },
			getType(e){ //选择分类
				this.form.type = e.target.value
			},
            getDetail(){
                let params = {
                    tid:this.id
                }
				getDetail(params).then(res =>{
					if(res.code==200){
						let data = res.data;
						this.coverUrl = data.img;
						this.$nextTick(()=>{
							this.form.setFieldsValue({
							    "title": data.topic,
							    "type":data.type,
							    "point":data.points,
							    "time":data.rule_time,
							    "number":data.choice_count,
								"date":[moment(parseInt(data.start_time) * 1000),moment(parseInt(data.end_time) * 1000)],
							})
							if(data.img){
							    let arr = {
							        uid:'-1',
							        status: 'done',
							        name:'封面图',
							        url:this.prefix+data.img,
							    }
							    this.$set(this.fileList,0,arr)
							}
						})
					}
				}).catch(res=>{
					
				})
            },
            cancel(){
                this.reset();
                this.$router.go(-1);
            },
			handleChange(info) {  //上传
			    console.log('info',info)
			    const status = info.file.status;
			    if (status !== 'uploading') {
			        
			    }
			    if(status == "removed"){
			        this.form.headimg = ''
			    }
			    if (status === 'done') {
			        if(info.file.response.code==0){
                        let enData = info.file.response.data;
                        //enData = decrypt(enData);
                        this.coverUrl = enData.path;
                    }else if(info.file.response.code==-98){
                        this.$message.error(`登录已过期,请重新登录`);
                        setTimeout(_=>{
                            localStorage.clear();
                            location.reload();
                        },1200)
                    }
			    } else if (status === 'error') {
			        this.$message.error(`${info.file.name} 上传失败.`);
			    }
			},
            reset(){
               this.id = ''
               this.visible = false
               this.$nextTick(()=>{
                   this.form.setFieldsValue({
                       "title": '',
                       "type":'1',
                       "point":'',
                       "time":'',
                       "number":'',
                       "date":null,
                   })
				   this.coverUrl = ''
               })
            },
			handleSubmit(e) {
			    // console.log(1)
			    e.preventDefault();
			    console.log(2)
			    let that = this
			    this.form.validateFields((err, values) => {
			        console.log('err',err,'values',values)
			        if (!err) {
			          //console.log('Received values of form: ', values);
			          let params = {
			                id:that.id,
			                topic:values.title,
			                type:values.type,
			                img:that.coverUrl,
			                points:values.point,
			                rule_time:values.time,
			                choice_count:values.number,
							start_time:moment(values.date[0]).format('YYYY-MM-DD HH:mm:ss'),
							end_time:moment(values.date[1]).format('YYYY-MM-DD HH:mm:ss')
			            }
						if(this.id){
							_edit(params).then(res =>{
							    if(res.code==200){
							        that.$message.success('编辑成功')
									that.$router.go(-1)
							    }else{
							        that.$message.success(res.msg)
							    }
							})
						}else{
							_add(params).then(res =>{
							    if(res.code==200){
							        that.$message.success('添加成功')
									that.$router.go(-1)
							    }else{
							        that.$message.success(res.msg) 
							    }
							})
						}
			            
			        }else{
			
			        }
			    });
			},
        },
        filters: {
        
        }
    }
</script>


<style scoped>
#submit{display: flex;justify-content: center;margin-top: 20px;}
#btn{margin-left:20px;}
.title{
    height:22px;
    font-size:16px;
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:400;
    color:rgba(51,51,51,1);
    line-height:22px;
    margin-bottom: 20px;
}
.main{
    margin-bottom: 25px;
}

</style>

